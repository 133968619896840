var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"content",attrs:{"id":"eu-view"}},[_c('div',{staticClass:"page-title"},[_c('h1',[_vm._v(_vm._s(_vm.$t("title.eu")))]),_c('div',{staticClass:"border-short"})]),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-6 md:mt-10"},[_c('img',{attrs:{"src":require("@/assets/eu-banner.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-10"},[_c('div',{staticClass:"inline-block text-lg"},[_c('h1',{staticClass:"text-2xl font-bold border-b-4 border-eu-blue"},[_vm._v(" Naziv projekta: ")]),_c('div',{staticClass:"border-short"})]),_c('h3',{staticClass:"mt-2 md:text-lg"},[_vm._v(" Projektiranje i proizvodnja linije za proizvodnju izolacijskih mata za toplovodne cijevi međugradskog grijanja - proizvodnja izolacijskih mata za toplovodne cijevi međugradskog grijanja. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-10"},[_c('div',{staticClass:"inline-block text-lg"},[_c('h1',{staticClass:"text-2xl font-bold border-b-4 border-eu-blue"},[_vm._v(" Kratki opis projekta: ")]),_c('div',{staticClass:"border-short"})]),_c('h3',{staticClass:"mt-2 md:text-lg"},[_vm._v(" Projekt se bavi ulaganjem u IRI i povećanjem konkurentnosti obrta Europlast kroz razvoj dva nova proizvoda - linije za proizvodnju te izolacijskih mata bez tehnološkog otpada. Partner na projektu je Strojarski fakultet Slavonski Brod (FSB). ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-10"},[_c('div',{staticClass:"inline-block text-lg"},[_c('h1',{staticClass:"text-2xl font-bold border-b-4 border-eu-blue"},[_vm._v(" Ciljevi i očekivani rezultati projekta: ")]),_c('div',{staticClass:"border-short"})]),_c('h3',{staticClass:"mt-2 md:text-lg"},[_vm._v(" Opći cilj projekta je razviti nove proizvode povećanjem ulaganja u IRI, jačanjem kapaciteta za IRI te suradnjom institucija za IRI. Općem cilju doprinijet će se realizacijom specifičnog cilja projekta, budući da će projekt rezultirati prototipom dva nova proizvoda za globalno tržište, koji će se po završetku projekta u potpunosti komercijalizirati. Ukupna vrijednost projekta je 6.840.898,27 kn, od čega EU sufinancira iznos od 3.508.730,08 kn. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-10"},[_c('div',{staticClass:"inline-block my-auto text-lg"},[_c('h3',[_c('span',{staticClass:"font-bold text-black"},[_vm._v("Razdoblje provedbe projekta:")]),_vm._v(" od 01.04.20. do 01.08.23. ")]),_c('h3',{staticClass:"mt-3"},[_c('span',{staticClass:"font-bold text-black"},[_vm._v("Kontakt osobe za više informacija:")]),_vm._v(" Bojan Banda (bojan.banda@europlast.hr), Predrag Banda (info@europlast.hr) ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-10 text-lg font-bold"},[_c('p',[_vm._v("Projekt je sufinancirala Europska unija iz Europskog fonda za regionalni razvoj. Za više informacija o Europskim strukturnim i investicijskim fondovima posjetite: "),_c('a',{staticClass:"hover:text-blue-400 font-normal",attrs:{"href":"https://strukturnifondovi.hr/"}},[_vm._v("https://strukturnifondovi.hr/")]),_vm._v(".")])])
}]

export { render, staticRenderFns }